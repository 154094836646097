/**
 * Created by Mikhail Menshenin on 15.08.2024
 */

var VillageAttackScene = VillageActionScene.extend({
    ctor: function (snapshotLoaded) {
        this._super();

        if (!snapshotLoaded) {
            this.village.prepareToAttack();
        }
    },

    getVillage: function () {
        return aisensia.villageManager.getAttackVillage();
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_VILLAGE_ATTACK);

        cleverapps.focusManager.showControlsTemporary("village");

        this.village.on("assault", this.hideHammer.bind(this), this);
        this.village.on("defense", this.hideHammer.bind(this), this);

        if (!this.village.isStateAttack()) {
            this.village.counter.trigger();
            this.controlPanel.hide();
        }
    },

    takeTransitNodes: function () {
        var hammer;
        if (cleverapps.scenes.transitNodes && cleverapps.scenes.getTransitNode("hammer")) {
            hammer = this.hammer = cleverapps.scenes.getTransitNode("hammer");
            hammer.replaceParentSamePlace(this);
            hammer.setPositionRound({ x: { align: "center" }, y: Math.round(hammer.getPosition().y) });
        } else if (this.village.isStateAttack()) {
            hammer = this.hammer = new cleverapps.Spine(bundles.transition_attack.jsons.attack_transition_json);
            hammer.setPositionRound({ align: "center" }, hammer.height * 0.4);
            hammer.setAnimationAndIdleAfter("close", "glow");
            this.addChild(hammer);
            cleverapps.scenes.addTransitNode(hammer, "hammer");
        }
    },

    addUI: function () {
        var styles = cleverapps.styles.VillageAttackScene;

        this.addControlPanel(styles);
    },

    addControlPanel: function (styles) {
        var controlPanel = this.controlPanel = new VillageAttackControlView(this.village);
        controlPanel.setPositionRound(styles.control);
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            cleverapps.UI.fitToBox(controlPanel, styles.control);
        }
        this.addChild(controlPanel);
        controlPanel.show();
    },

    handleFinishEvent: function (f) {
        this.village.counter.registerStage(10, function () {
            if (this.village.isAttacked() || this.village.defended()) {
                this.showResultPanel(f);
            }
        }.bind(this));
    },

    hideHammer: function () {
        this.hammer.runAction(new cc.Sequence(
            new cc.DelayTime(0.8),
            new cc.MoveBy(0.2, 0, -this.hammer.height * 1.5).easing(cc.easeIn(1)),
            new cc.Hide()
        ));
    },

    showResultPanel: function (f) {
        var styles = cleverapps.styles.VillageAttackScene;
        var resultPanel = this.resultPanel = new VillageAttackResultView(this.village);
        resultPanel.setPositionRound(styles.result.position);
        resultPanel.setScale2(styles.result.scale);
        this.addChild(resultPanel);
        resultPanel.show();

        var label = this.tapToContinue = new TapToContinue({
            text: "Window.TapToContinue"
        });
        label.setPositionRound(styles.tapToContinue);
        this.addChild(label);
        label.show();

        cleverapps.UI.onClick(this, function () {
            this.hideResultPanel(f);
        }.bind(this), {
            interactiveScale: false
        });
    },

    hideResultPanel: function (f) {
        this.resultPanel.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.CallFunc(function () {
                this.tapToContinue.hide();
            }.bind(this)),
            new cc.MoveBy(0.4, 0, -this.resultPanel.height).easing(cc.easeOut(3)),
            new cc.CallFunc(f)
        ));
    },

    listBundles: function () {
        var bundles = this._super();
        bundles.push("transition_attack");
        return bundles;
    }
});

cleverapps.styles.VillageAttackScene = {
    hammer: {
        x: { align: "center", dx: -47 },
        y: { align: "bottom", dy: -8 }
    },

    control: {
        width: 520,
        height: 160,
        x: { align: "center", dx: 3 },
        y: { align: "top", dy: 35 }
    },

    result: {
        scale: [0.6, 1, 1],
        position: [{
            x: { align: "center" },
            y: { align: "bottom", dy: -20 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: -40 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: -40 }
        }],
    },

    tapToContinue: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 250 }
    }
};

cleverapps.Environment.SCENE_VILLAGE_ATTACK = "villageAttack";