/**
 * Created by Mikhail Menshenin on 20.08.2024
 */

MenuBar.listItems = function () {
    var items = [];

    if (cleverapps.config.name === "yatzy") {
        items.push(cleverapps.MenuBarItems.GoldItem);
        items.push(cleverapps.MenuBarItems.LivesItem);
        items.push(cleverapps.MenuBarItems.CoinsItem);

        return items;
    }

    if (cleverapps.config.name === "coinkingdom") {
        items.push(cleverapps.MenuBarItems.GoldItem);
        items.push(cleverapps.MenuBarItems.CoinsItem);

        return items;
    }

    items.push(cleverapps.MenuBarItems.GoldItem);

    if (cleverapps.lives) {
        items.push(cleverapps.MenuBarItems.LivesItem);
    }

    if (cleverapps.config.soft) {
        items.push(cleverapps.MenuBarItems.CoinsItem);
    }

    return items;
};

cleverapps.MenuBarItems = {};
cleverapps.MenuBarItems.GoldItem = {
    name: "GoldItem",
    lottery: true,
    isClickable: function () {
        return cleverapps.flags.monetization !== cleverapps.Flags.MONETIZATION_DISABLED;
    },
    clickableUpdater: function (f) {
        cleverapps.flags.on("change:monetization", f, this);
    },
    targets: "hard",
    plusButton: true,
    value: function () {
        return cleverapps.user.gold;
    },
    updater: function (f) {
        cleverapps.user.on("changeHard", f, this);
    },
    attentionUpdater: function (f) {
        cleverapps.adsLimits.on("update", f, this);

        if (cleverapps.subscription) {
            cleverapps.subscription.on("update", f, this);
        }

        if (cleverapps.growthFund) {
            cleverapps.growthFund.on("updateState", f, this);
        }
    },
    sparks: true,
    attention: function () {
        if (ShopProductSource.listProducts(ShopWindow.TABS.HARD_CURRENCY).indexOf("hardForVideo") !== -1
            && cleverapps.adsLimits.state(AdsLimits.TYPES.HARD) === AdsLimits.STATE_READY) {
            return true;
        }
        if (ShopProductSource.listProducts(ShopWindow.TABS.HARD_CURRENCY).indexOf("subsMonth") !== -1
            && cleverapps.subscription && cleverapps.subscription.needsAttention()) {
            return true;
        }
        if (cleverapps.growthFund && cleverapps.growthFund.needsAttention()) {
            return true;
        }
        return false;
    },
    availableOnScenes: function () {
        var scenes = [
            cleverapps.Environment.SCENE_MAIN,
            cleverapps.Environment.SCENE_SLOT_MACHINE,
            cleverapps.Environment.SCENE_GAME
        ];
        return scenes;
    },
    icon: bundles.menubar.frames.gold_png,
    onClickWindow: HardCurrencyShopWindow,
    deltaSound: bundles.menubar.urls.coins_effect
};

cleverapps.MenuBarItems.LivesItem = {
    name: "LivesItem",
    lottery: true,
    targets: "lives",
    plusButton: true,
    attentionUpdater: function (f) {
        if (Game.currentGame && Game.currentGame.energyLottery) {
            Game.currentGame.energyLottery.onLotteryAttentionChanged = f;
        }
    },
    attention: function () {
        if (Game.currentGame && Game.currentGame.energyLottery) {
            return Game.currentGame.energyLottery.isReady();
        }
    },
    availableOnScenes: function () {
        var scenes = [cleverapps.Environment.SCENE_BONUS_WORLD];

        var expedition = cleverapps.travelBook.getCurrentPage();
        if (expedition.withEnergy()) {
            scenes.push(cleverapps.Environment.SCENE_MAIN);
        }

        if (cleverapps.dailyCup.withLives()) {
            scenes.push(cleverapps.Environment.SCENE_DAILY_CUP);
        }

        return scenes;
    },
    force: Forces.BUY_ENERGY_ICON,
    viewClass: MenuBarWithTimeoutView,
    modelClass: LivesMenuBarItemModel,
    icon: function () {
        return cleverapps.skins.getSlot("menubar_life_png") || bundles.menubar.frames.menubar_life_png;
    },
    onClickWindow: LivesShopWindow,
    filter: function () {
        if (cleverapps.unlimitedLives && cleverapps.unlimitedLives.freeOfferIsAvailable()) {
            cleverapps.unlimitedLives.showFreeOffer(function () { });
            return false;
        }

        if (cleverapps.unlimitedLives && cleverapps.unlimitedLives.checkBuyed() || connector.platform.oneOf(connector.DIGITAL)) {
            return false;
        }
        return true;
    },
    sparks: true,
    deltaSound: cleverapps.config.lives ? bundles.menubar.urls.lives_fly_finish_effect : bundles.menubar.urls.energy_fly_finish_effect
};

cleverapps.MenuBarItems.CoinsItem = {
    name: "CoinsItem",
    targets: "soft",
    plusButton: true,
    value: function () {
        return cleverapps.user.soft;
    },
    updater: function (f) {
        cleverapps.user.on("changeSoft", f, this);
    },
    attentionUpdater: function (f) {
        cleverapps.adsLimits.on("update", f, this);
    },
    attention: function () {
        return false;
    },
    onClickWindow: SoftCurrencyShopWindow,
    icon: bundles.menubar.frames.coin_png,
    force: Forces.MENU_BAR_SOFT_FORCE,
    availableOnScenes: function () {
        return [
            cleverapps.Environment.SCENE_MAIN,
            cleverapps.Environment.SCENE_SLOT_MACHINE,
            cleverapps.Environment.SCENE_GAME
        ];
    },
    sparks: true,
    deltaSound: bundles.menubar.urls.coins_effect
};