/**
 * Created by Slava on 14.10.2024
 */

LevelMasteryView.prototype.createTargetIcon = function (styles) {
    var node = new cc.Node();

    var symbol = SlotMachine.SYMBOLS[aisensia.slotMachine.levelMasteryTargetType];
    var icon = new cc.Sprite(symbol.icon);
    icon.setPositionRound(styles.icon);
    icon.setScale(0.9);
    node.addChild(icon);

    node.setContentSize2(icon.getContentSize());
    return node;
};

LevelMastery.prototype.getStageReward = function (stage) {
    var prize = stage.prize;

    var rewards = [];
    Object.keys(prize).forEach(function (type) {
        rewards.push(new Reward(type, prize[type], {}));
    });

    return rewards;
};

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LEVELMASTERY_PROGRESS_REWARD_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelMasteryView, {
    progressBar: {
        type: ScaledProgressBar.Types.golden,
        barText: {
            dy: 2,
            font: cleverapps.styles.FONTS.STARS_PROGRESS_BAR_TEXT
        }
    },

    reward: {
        x: { align: "right", dx: 30 },
        y: { align: "center", dy: 0 },
        width: 175,
        height: 85
    },

    reward_icons: {
        x: { align: "right", dx: 55 },
        y: { align: "center", dy: 13 },

        margin: 40,

        first: {
            width: 54,
            height: 73,
            text: {
                x: { align: "center" },
                y: { align: "center", dy: -30 }
            }
        },

        second: {
            width: 54,
            height: 73,
            text: {
                x: { align: "center" },
                y: { align: "center", dy: -30 }
            }
        }
    },

    position: {
        x: { align: "center", dx: -30 },
        y: { align: "center" }
    },

    cupCircle: {
        x: { align: "center" },
        y: { align: "center", dy: 10 },
    },

    target: {
        x: { align: "left" },
        y: { align: "center", dy: -3 },

        icon: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    Type: {
        scene: {
            width: 525,
        },
        window: {
            width: 600
        }
    },
});