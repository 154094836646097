/**
 * Created by Mikhail Menshenin on 26.08.2024
 */

var Cross = function (village, id) {
    cleverapps.EventEmitter.call(this);
    this.village = village;
    this.id = id;
    this.raided = false;
    this.gold = 0;
};

Cross.prototype = Object.create(cleverapps.EventEmitter.prototype);
Cross.prototype.constructor = Cross;

Cross.prototype.onClick = function () {
    this.village.onCrossClick(this);
};

Cross.prototype.dig = function (f) {
    this.raided = true;
    this.trigger("dig", f);
};

Cross.prototype.getInfo = function () {
    return {
        raided: this.raided
    };
};
