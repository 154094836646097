/**
 * Created by Mikhail Menshenin on 20.08.2024
 */

cleverapps.overrideStyles(cleverapps.styles.ToolbarView, {
    items: {
        dy: 2
    },
});

cleverapps.overrideStyles(cleverapps.styles.UpMenuContainer, {
    scale: [0.6, 0.8, 1],
    vertical: {
        padding: {
            x: 16,
            y: 0
        },
        menuBar: {
            x: { align: "left", dx: -18 },
            y: { align: "center" }
        }
    },
    horizontal: {
        padding: {
            x: 80,
            y: 10
        },
        menuBar: {
            x: { align: "left", dx: -30 },
            y: { align: "center" }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UI.Avatar, {
    x: 6,
    top: 6,
    bottom: 12
});

cleverapps.overrideStyles(cleverapps.styles.SideBarSlot, {
    wideModePadding: [
        {
            top: 120,
            horizontal: 20
        },
        {
            top: 246,
            horizontal: 62
        },
        {
            top: 246,
            horizontal: 62
        }
    ],
    margin: 32
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    rewards: {
        fitTo: {
            width: 200
        },

        positions: {
            default: {
                hard: {
                    x: -235,
                    y: 175
                },
                soft: {
                    x: 320,
                    y: 60
                }
            }
        }
    }
});

ScaledProgressBar.Types = cleverapps.overrideTemplates(ScaledProgressBar.Types, {
    golden: {
        progress: bundles.progress_bar.frames.bar_green_large,
        background: bundles.progress_bar.frames.bg_golden
    }
});