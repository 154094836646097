/**
 * Created by Mikhail Menshenin on 20.09.2024
 */

cleverapps.overrideStyles(cleverapps.styles.HutView, {
    villages: {
        0: [[{ "x": -34, "y": 470 }, { "x": -409, "y": 117 }, { "x": -409, "y": 117 }], [{ "x": 223, "y": 257 }, { "x": 439, "y": 160 }, { "x": 439, "y": 160 }], [{ "x": -200, "y": 0 }, { "x": -260, "y": -140 }, { "x": -260, "y": -140 }], [{ "x": 210, "y": -110 }, { "x": 30, "y": 160 }, { "x": 30, "y": 160 }], [{ "x": -220, "y": -370 }, { "x": 200, "y": -100 }, { "x": 200, "y": -100 }]],
        1: [[{ "x": 280, "y": -110 }, { "x": 520, "y": -110 }, { "x": 520, "y": -110 }], [{ "x": 230, "y": 280 }, { "x": -300, "y": 170 }, { "x": -300, "y": 170 }], [{ "x": -160, "y": 40 }, {}, {}], [{ "x": -90, "y": 420 }, { "x": 430, "y": 230 }, { "x": 430, "y": 230 }], [{}, { "x": -480, "y": -300 }, { "x": -480, "y": -300 }]],
        2: [[{ "x": { "align": "center", "dx": 200 }, "y": { "align": "center", "dy": 150 } }, { "x": { "align": "center", "dx": -72 }, "y": { "align": "center", "dy": 154 } }, { "x": { "align": "center", "dx": -72 }, "y": { "align": "center", "dy": 154 } }], [{ "x": { "align": "center", "dx": 133 }, "y": { "align": "center", "dy": -290 } }, { "x": { "align": "center", "dx": 284 }, "y": { "align": "center", "dy": -166 } }, { "x": { "align": "center", "dx": 284 }, "y": { "align": "center", "dy": -166 } }], [{ "x": { "align": "center", "dx": -215 }, "y": { "align": "center", "dy": -500 } }, { "x": { "align": "center", "dx": -254 }, "y": { "align": "center", "dy": -169 } }, { "x": { "align": "center", "dx": -254 }, "y": { "align": "center", "dy": -169 } }], [{ "x": { "align": "center", "dx": -53 }, "y": { "align": "center", "dy": 470 } }, { "x": { "align": "center", "dx": 416 }, "y": { "align": "center", "dy": 174 } }, { "x": { "align": "center", "dx": 416 }, "y": { "align": "center", "dy": 174 } }], [{ "x": { "align": "center", "dx": -160 }, "y": { "align": "center", "dy": 10 } }, { "x": { "align": "center", "dx": -470 }, "y": { "align": "center", "dy": 155 } }, { "x": { "align": "center", "dx": -470 }, "y": { "align": "center", "dy": 155 } }]]
    }
});

cleverapps.overrideStyles(cleverapps.styles.CrossView, {
    villages: {
        0: [[{ "x": -180, "y": 260 }, { "x": -370, "y": -50 }, { "x": -370, "y": -50 }], [{ "x": 100, "y": 30 }, { "x": -100, "y": 60 }, { "x": -100, "y": 60 }], [{ "x": -180, "y": -170 }, { "x": 440, "y": -90 }, { "x": 440, "y": -90 }], [{ "x": 70, "y": -220 }, { "x": -70, "y": -100 }, { "x": -70, "y": -100 }]],
        1: [[{ "x": -20, "y": 260 }, { "x": -250, "y": 40 }, { "x": -250, "y": 40 }], [{ "x": 200, "y": 120 }, { "x": 230, "y": 100 }, { "x": 230, "y": 100 }], [{ "x": -40, "y": -90 }, { "x": 350, "y": -60 }, { "x": 350, "y": -60 }], [{ "x": 160, "y": -220 }, { "x": -50, "y": -190 }, { "x": -50, "y": -190 }]]
    }
});