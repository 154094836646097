/**
 * Created by Mikhail Menshenin on 25.04.2024
 */

var LevelMasteryRewardView = cc.Node.extend({
    ctor: function (reward) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var icon = this.icon = reward.getIcon();
        var styles = cleverapps.styles.LevelMasteryRewardView;

        var ribbonBack = this.ribbonBack = cleverapps.UI.createScale9Sprite(bundles.levelmastery.frames.ribbon_back_png);
        ribbonBack.setContentSize(Math.max(styles.ribbon.minWidth, icon.getContentSize().width + 2 * styles.ribbon.padding), styles.ribbon.height);
        ribbonBack.setPositionRound(styles.ribbon);
        this.addChild(ribbonBack);

        this.setContentSize(icon.getContentSize());
        icon.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        this.addChild(icon);

        var ribbon = this.ribbon = cleverapps.UI.createScale9Sprite(bundles.levelmastery.frames.ribbon_front_png);
        ribbon.setContentSize(Math.max(styles.ribbon.minWidth, icon.getContentSize().width + 2 * styles.ribbon.padding), styles.ribbon.height);
        ribbon.setPositionRound(styles.ribbon);
        this.addChild(ribbon);

        var ribbonText = this.ribbonText = cleverapps.UI.generateTTFText(reward.getText("x"), cleverapps.styles.FONTS.RIBBON_TEXT);
        ribbonText.setPositionRound(styles.ribbon.text);
        ribbon.addChild(ribbonText);
    },

    takeOffAnimation: function () {
        this.ribbonBack.runAction(
            new cc.MoveBy(0.1, 0, -15)
        );

        this.ribbon.runAction(
            new cc.MoveBy(0.1, 0, -15)
        );

        this.icon.runAction(
            new cc.MoveBy(0.1, 0, 15)
        );
    }
});

cleverapps.styles.LevelMasteryRewardView = {
    ribbon: {
        x: { align: "center" },
        y: { align: "center", dy: -30 },
        minWidth: 198,
        height: 101,
        padding: 44,

        text: {
            x: { align: "center" },
            y: { align: "center", dy: -16 }
        }
    }
};