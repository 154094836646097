/**
 * Created by Slava on 24.01.2025
 */

var VillageAdapter = function () {
    this.locations = {};
};

VillageAdapter.prototype.load = function () {
    this.updateInfo(cleverapps.dataLoader.load(DataLoader.TYPES.META));
};

VillageAdapter.prototype.save = function (fromSever) {
    cleverapps.dataLoader.save(DataLoader.TYPES.META, this.getInfo());
    if (!fromSever) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
};

VillageAdapter.prototype.updateInfo = function (data) {
    data = data || {};

    cleverapps.meta.logic.currentLocationId = data.level || 0;

    this.locations[cleverapps.meta.logic.currentLocationId] = data;
};

VillageAdapter.prototype.getInfo = function () {
    return this.locations[cleverapps.meta.logic.currentLocationId] || {};
};

VillageAdapter.prototype.loadLocation = function (locationId) {
    return this.locations[locationId] || {};
};

VillageAdapter.prototype.saveLocation = function (locationId, data) {
    this.locations[locationId] = data;
    cleverapps.meta.save();
};

VillageAdapter.prototype.reset = function () {
    this.locations = {};
};

Meta.prototype.createAdapter = function () {
    return new VillageAdapter();
};