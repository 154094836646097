/**
 * Created by Mikhail Menshenin on 03.07.2024
 */

SlotMachine.REELS_AMOUNT = 3;
SlotMachine.REEL_SPIN_DELAY = 300;

SlotMachine.COIN_SYMBOL = {
    type: "coin",
    exclude: ["stack"],
    icon: bundles.slotmachine.frames.coin_png,
    sliding_icon: bundles.slotmachine.frames.coin_sliding_png
};

SlotMachine.STACK_SYMBOL = {
    type: "stack",
    exclude: ["coin"],
    icon: bundles.slotmachine.frames.stack_png,
    sliding_icon: bundles.slotmachine.frames.stack_sliding_png
};

SlotMachine.ENERGY_SYMBOL = {
    type: "energy",
    icon: bundles.slotmachine.frames.energy_png,
    sliding_icon: bundles.slotmachine.frames.energy_sliding_png
};

SlotMachine.ATTACK_SYMBOL = {
    type: "attack",
    icon: bundles.slotmachine.frames.attack_png,
    sliding_icon: bundles.slotmachine.frames.attack_sliding_png
};

SlotMachine.SHIELD_SYMBOL = {
    type: "shield",
    icon: bundles.slotmachine.frames.shield_png,
    icon_large: bundles.slotmachine.frames.shield_large_png,
    sliding_icon: bundles.slotmachine.frames.shield_sliding_png
};

SlotMachine.RAID_SYMBOL = {
    type: "raid",
    icon: bundles.slotmachine.frames.raid_png,
    sliding_icon: bundles.slotmachine.frames.raid_sliding_png
};

SlotMachine.SYMBOLS = {};
SlotMachine.SYMBOLS[SlotMachine.COIN_SYMBOL.type] = SlotMachine.COIN_SYMBOL;
SlotMachine.SYMBOLS[SlotMachine.STACK_SYMBOL.type] = SlotMachine.STACK_SYMBOL;
SlotMachine.SYMBOLS[SlotMachine.ENERGY_SYMBOL.type] = SlotMachine.ENERGY_SYMBOL;
SlotMachine.SYMBOLS[SlotMachine.ATTACK_SYMBOL.type] = SlotMachine.ATTACK_SYMBOL;
SlotMachine.SYMBOLS[SlotMachine.SHIELD_SYMBOL.type] = SlotMachine.SHIELD_SYMBOL;
SlotMachine.SYMBOLS[SlotMachine.RAID_SYMBOL.type] = SlotMachine.RAID_SYMBOL;

SlotMachineReel.SYMBOLS = [
    SlotMachine.ATTACK_SYMBOL,
    SlotMachine.STACK_SYMBOL,
    SlotMachine.ENERGY_SYMBOL,
    SlotMachine.COIN_SYMBOL,
    SlotMachine.RAID_SYMBOL,
    SlotMachine.STACK_SYMBOL,
    SlotMachine.SHIELD_SYMBOL,
    SlotMachine.COIN_SYMBOL,
    SlotMachine.ATTACK_SYMBOL,
    SlotMachine.STACK_SYMBOL,
    SlotMachine.RAID_SYMBOL,
    SlotMachine.SHIELD_SYMBOL,
    SlotMachine.COIN_SYMBOL
];

SlotMachine.getRewardByVillageCost = function (coeff) {
    return function () {
        var village = cleverapps.meta.location;
        var cost = village.cost;
        var coins = Math.round(coeff * cost / 100) * 100;
        return {
            soft: coins
        };
    };
};

SlotMachine.PAYLINES = [
    {
        payline: ["coin", "coin", "coin"],
        animation: "3coins",
        reward: SlotMachine.getRewardByVillageCost(0.00513),
        sound: bundles.game.urls.slot_coin_win
    },
    {
        payline: ["coin", "coin"],
        animation: "2coins",
        reward: SlotMachine.getRewardByVillageCost(0.00051),
        sound: bundles.game.urls.slot_coin
    },
    {
        payline: ["coin"],
        animation: "1coin",
        reward: SlotMachine.getRewardByVillageCost(0.00026),
        sound: bundles.game.urls.slot_coin
    },
    {
        payline: ["stack", "stack", "stack"],
        animation: "3bags",
        reward: SlotMachine.getRewardByVillageCost(0.02051),
        sound: bundles.game.urls.slot_stack
    },
    {
        payline: ["stack", "stack"],
        animation: "2bags",
        reward: SlotMachine.getRewardByVillageCost(0.00256),
        sound: bundles.game.urls.slot_coin
    },
    {
        payline: ["stack"],
        animation: "1bag",
        reward: SlotMachine.getRewardByVillageCost(0.00128),
        sound: bundles.game.urls.slot_coin
    },
    {
        payline: ["shield", "shield", "shield"],
        spine: bundles.slotmachine.jsons.reward_shield_json,
        icon_animation: "shield_reward",
        reward: {
            shields: 1
        },
        message: "SlotMachine.Message.Shield",
        sound: bundles.game.urls.slot_shield
    },
    {
        payline: ["energy", "energy", "energy"],
        spine: bundles.slotmachine.jsons.reward_energy_json,
        icon_animation: "energy_reward",
        reward: {
            energy: 10
        },
        sound: bundles.game.urls.slot_energy
    },
    {
        payline: ["attack", "attack", "attack"],
        action: SlotMachineActions.attack,
        message: "SlotMachine.Message.Attack",
        sound: bundles.game.urls.slot_attack
    },
    {
        payline: ["raid", "raid", "raid"],
        spine: bundles.slotmachine.jsons.reward_raid_json,
        icon_animation: "raid_reward",
        action: SlotMachineActions.raid,
        message: "SlotMachine.Message.Raid",
        sound: bundles.game.urls.slot_raid
    }
];

SlotMachine.FORCE_PAYLINE = [SlotMachine.COIN_SYMBOL.type, SlotMachine.COIN_SYMBOL.type, SlotMachine.COIN_SYMBOL.type];

if (cleverapps.config.debugMode) {
    SlotMachine.DEBUG_PAYLINES = [
        [["stack", "attack", "stack"], ["coin", "attack", "coin"], ["coin", "attack", "shield"], ["shield", "attack", "stack"], ["stack", "stack", "stack"], ["coin", "coin", "coin"]],
        ["energy", "energy", "energy"],
        [["raid", "shield", "energy"], ["shield", "energy", "attack"], ["energy", "attack", "raid"], ["raid", "raid", "shield"]],
        ["attack", "attack", "attack"],
        ["raid", "raid", "raid"],
        ["shield", "shield", "shield"]
    ];
}