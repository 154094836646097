/**
 * Created by Mikhail Menshenin on 22.01.2025
 */

var VillageLogic = function () {
    this.currentLocationId = 0;
};

VillageLogic.prototype.reset = function () {
    this.currentLocationId = 0;
};

VillageLogic.prototype.moveNextLocation = function () {
    this.currentLocationId += 1;
};

VillageLogic.prototype.getMainLocation = function () {
    return this.currentLocationId;
};

VillageLogic.prototype.getLocationClass = function () {
    return Village;
};

VillageLogic.prototype.getMainScene = function () {
    return VillageScene;
};

VillageLogic.prototype.listAvailableLocations = function () {
    var configs = this.getConfigs();
    var ids = configs.map(function (config) {
        return config.level;
    });
    var players = cleverapps.villagePlayers && cleverapps.villagePlayers.getPlayers() || [];
    var locations = ids.concat(players.map(function (player) {
        return player.locationId;
    }));

    return locations;
};

VillageLogic.prototype.listActiveLocations = function () {
    var locationId = this.currentLocationId;
    var players = cleverapps.villagePlayers && cleverapps.villagePlayers.getPlayers() || [];
    var locations = [locationId].concat(players.map(function (player) {
        return player.locationId;
    }));
    return locations;
};

VillageLogic.prototype.nextLocationId = function (locationId) {
    return parseInt(locationId) + 1;
};

VillageLogic.prototype.processChatMessage = function () {

};

VillageLogic.prototype.getConfig = function (level) {
    var configs = this.getConfigs();
    return configs[level];
};

VillageLogic.prototype.getConfigs = function () {
    if (this.configs) {
        return this.configs;
    }

    var configs = [];
    for (var id = 0; ; id++) {
        var bundleBgIds = [
            "village_background_horizontal_" + id,
            "village_background_vertical_" + id
        ];
        var existsBg = bundleBgIds.filter(function (bundleId) {
            return !bundles[bundleId];
        }).length === 0;
        if (!existsBg) {
            break;
        }
        var config = {
            level: id,
            name: "village_" + id
        };

        var huts = {};
        var bundleId = "village_" + id;
        var bundle = bundles[bundleId];
        if (bundle) {
            var frames = Object.keys(bundle.frames);
            frames.forEach(function (frame) {
                var hut = frame.slice(0, frame.lastIndexOf("_"));
                var level = parseInt(frame.slice(hut.length + 1));
                if (!huts[hut]) {
                    huts[hut] = {
                        name: hut,
                        maxLevel: level
                    }
                }
                if (huts[hut].maxLevel < level) {
                    huts[hut].maxLevel = level;
                }
            });
        }
        config.huts = Object.keys(huts).sort().map(function (hut) {
            return huts[hut];
        });

        configs.push(config);
    }

    this.configs = configs;
    return configs;
};

Meta.prototype.createLogic = function () {
    return new VillageLogic();
};