/**
 * Created by Mikhail Menshenin on 29.08.2024
 */

var RaidsView = cc.Node.extend({
    ctor: function (village) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        var styles = cleverapps.styles.RaidsView;
        this.village = village;
        this.shovels = [];
        for (var index = 0; index < Village.MAX_RAIDS; index++) {
            var shovel = new cc.Sprite(bundles.village.frames.shovel_png);
            this.shovels.push(shovel);
        }
        var layout = new cleverapps.Layout(this.shovels, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        this.setContentSize2(layout.getContentSize());
        layout.setPosition(this.width / 2, this.height / 2);
        this.addChild(layout);

        this.updateShovels(village);
        village.on("changeRaids", this.updateShovels.bind(this, village), this);

        this.show();
    },

    updateShovels: function (village) {
        this.shovels.forEach(function (shovel, index) {
            if ((shovel.visible !== (index < village.raids)) && shovel.visible) {
                shovel.runAction(AnimationsLibrary.disappear(shovel));
            } else {
                shovel.setVisible(index < village.raids);
            }
        });
    },

    show: function () {
        var village = this.village;
        this.shovels.forEach(function (shovel, index) {
            if (index < village.raids) {
                shovel.runAction(AnimationsLibrary.showUp(shovel, {
                    delay: 2.2 + 0.2 * index,
                    duration: 0.5
                }));
            }
        });
    }
});

cleverapps.styles.RaidsView = {
    margin: 10
};