/**
 * Created by mac on 9/21/23
 */

var aisensia = {};

cleverapps.whenAllInitialized(
    function () {
        // SLOTMACHINE
        if (cleverapps.config.name === "coinkingdom") {
            cleverapps.sideBar.addTemporaryIcon(new SlotMachineIcon());
            cleverapps.sideBar.addTemporaryIcon(new MapIcon());
        }

        // UPMENU CONTAINER
        if (cleverapps.config.name === "coinkingdom") {
            if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                cleverapps.overrideStyles(cleverapps.styles.UpMenuContainer, cleverapps.styles.UpMenuContainer.vertical);
            } else {
                cleverapps.overrideStyles(cleverapps.styles.UpMenuContainer, cleverapps.styles.UpMenuContainer.horizontal);
            }
        }

        if (cleverapps.config.debugMode) {
            MedalCollectionTool.init();
        }
    }
);

cleverapps.createGlobalObjects = cleverapps.extendFunc(cleverapps.createGlobalObjects, function () {
    this._super();

    var isNewUser = cleverapps.dataLoader.haveSavedData() === false;
    cleverapps.dailyTasks = new DailyTasks(isNewUser);

    // TOOLBAR
    if (cleverapps.config.name === "yatzy") {
        cleverapps.toolbar.addItem(new MiniGameToolbarItem());
        cleverapps.toolbar.addItem(new DailyTasksToolbarItem());
        cleverapps.toolbar.addItem(new LeaderBoardToolbarItem());
    }

    // COMPETITION
    if (cleverapps.config.name === "coinkingdom") {
        cleverapps.competitionPlayers = new CompetitionPlayers();
    }
});

if (cleverapps.config.name === "yatzy") {
    cleverapps.Lives.TIMEOUT = cleverapps.parseInterval(cleverapps.config.debugMode ? "5 minute" : "30 minutes");
}
