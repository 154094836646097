/**
 * Created by Mikhail Menshenin on 20.08.2024
 */

var VillagePlayers = function () {
    cleverapps.EventEmitter.call(this);

    this.running = true;
    this.type = "village_players";
    this.results = cleverapps.competitionPlayers.listFakePlayers(5);
    this.raided = [];
    this.raidPlayer = undefined;

    if (this.countFakePlayers() > 0) {
        this.onPlayersAvailable();
    }
}

VillagePlayers.prototype = Object.create(cleverapps.EventEmitter.prototype);
VillagePlayers.prototype.constructor = VillagePlayers;

VillagePlayers.prototype.countFakePlayers = Competition.prototype.countFakePlayers;
VillagePlayers.prototype.onPlayersAvailable = Competition.prototype.onPlayersAvailable;
VillagePlayers.prototype.replaceFakePlayers = Competition.prototype.replaceFakePlayers;

VillagePlayers.prototype.save = function () {
    var players = this.results.filter(function (player) {
        return !player.fake;
    });
    players.forEach(function (player) {
        if (player.villageLevel === undefined) {
            player.villageLevel = cleverapps.Random.random(0, 8);
        }
        if (player.gold === undefined) {
            player.gold = cleverapps.Random.random(50, 99) * 1000;
        }
        player.locationId = player.villageLevel + "_" + player.id;
    });
    cleverapps.UI.Avatar.preload(players);
    cleverapps.meta.resetAvailableLocations();
    this.trigger("update");
};

VillagePlayers.prototype.getPlayers = function () {
    return this.results.filter(function (player) {
        return !player.fake;
    });
};

VillagePlayers.prototype.getAttackPlayer = function () {
    if (cleverapps.config.debugMode) {
        if (this.attackPlayer) {
            return this.attackPlayer;
        }
    }
    return cleverapps.Random.choose(this.results.filter(function (player) {
        return player !== this.raidPlayer;
    }.bind(this)));
};

VillagePlayers.prototype.getRaidPlayer = function () {
    if (!this.raidPlayer) {
        var raidPlayer = cleverapps.Random.choose(this.getPlayersToRaid());

        if (raidPlayer.villageLevel === undefined) {
            raidPlayer.villageLevel = cleverapps.Random.random(0, 8);
        }

        this.raidPlayer = raidPlayer;
    }

    return this.raidPlayer;
}

VillagePlayers.prototype.resetRaidPlayer = function () {
    if (this.raidPlayer) {
        this.raided.push(this.raidPlayer);
    }

    if (!this.getPlayersToRaid().length) {
        this.raided.length = 0;
    }

    this.raidPlayer = undefined;
};

VillagePlayers.prototype.getPlayersToRaid = function () {
    return cleverapps.substract(this.results, this.raided, function (player) {
        return player.id;
    });
};

VillagePlayers.prototype.getCurrentPlayer = function () {
    var villageLevel = cleverapps.meta.currentLocationId;
    return {
        id: connector.platform.getUserID(),
        villageLevel: villageLevel
    };
};

VillagePlayers.prototype.getPlayer = function (playerId) {
    var player = this.getPlayers().find(function (player) {
        return player.id === playerId;
    });
    return player;
};

VillagePlayers.prototype.addPlayer = function (player) {
    var playerExist = this.getPlayer(player.id);

    if (!playerExist) {
        this.results.push(player);
        this.save();
        return;
    }

    playerExist.villageLevel = player.villageLevel;
    playerExist.name = player.name;
    playerExist.avatar = player.avatar;
    playerExist.gold = player.gold;

    this.save();
};

cleverapps.whenAllInitialized(function () {
    cleverapps.villagePlayers = new VillagePlayers();
});
