/**
 * Created by Mikhail Menshenin on 25.04.2024
 */

var LevelMasteryWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission) {
        var styles = cleverapps.styles.LevelMasteryWindow;

        this._super({
            title: "LevelMasteryWindow.Title",
            name: "LevelMasteryWindow",
            content: this.createContent(styles),
            styles: styles,
            noPadding: true,
            help: function () {
                new GuideWindow({
                    name: "LevelMasteryGuideWindow",
                    bundle: bundles.levelmasteryguidewindow
                });
            }
        });

        this.closeButton.setType(styles.close);

        mission.needShowStartWindow = false;
    },

    createContent: function (styles) {
        var bgPatternFrame = bundles.levelmasterywindow.frames.window_purple_bg_pattern_png;
        var bgPattern = new cc.Sprite(bgPatternFrame);
        var heightAdd = 0;
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            heightAdd = Math.round(cleverapps.styles.LevelMasteryWindow.grid.row.card.height / bgPattern.height) * bgPattern.height;
        }
        var content = this.content = new cc.Node();
        content.setContentSize2(styles.width, styles.height + heightAdd);

        var bg = new cc.Scale9Sprite(bundles.levelmasterywindow.frames.window_purple_background_png);
        bg.setContentSize2(styles.bg.width, styles.bg.height + heightAdd);
        bg.setPositionRound(styles.bg);
        content.addChild(bg);

        var bgPatterns = [bgPattern];
        var bgPatternColCount = Math.floor(bg.width / bgPattern.width);
        var bgPatternRowCount = Math.floor(bg.height / bgPattern.height);
        var bgPatternCount = bgPatternColCount * bgPatternRowCount;
        cleverapps.rangeArray(1, bgPatternCount - 1).forEach(function () {
            bgPatterns.push(new cc.Sprite(bgPatternFrame));
        });
        var bgPatternLayout = new cleverapps.GridLayout(bgPatterns, {
            columns: bgPatternColCount
        });
        bgPatternLayout.setPositionRound(styles.pattern);
        bg.addChild(bgPatternLayout);

        var fgPatternFrame = bundles.levelmasterywindow.frames.window_purple_foreground_png;
        var fgPatterns = cleverapps.rangeArray(1, bgPatternColCount).map(function () {
            return new cc.Sprite(fgPatternFrame);
        });
        var fg = new cleverapps.Layout(fgPatterns, { direction: cleverapps.UI.HORIZONTAL });
        fg.setPositionRound(styles.fg);
        fg.setLocalZOrder(10);
        content.addChild(fg);

        var fgBottomPatterns = cleverapps.rangeArray(1, bgPatternColCount).map(function () {
            var pattern = new cc.Sprite(fgPatternFrame);
            pattern.setScaleY(-1);
            return pattern;
        });
        var fgBottom = new cleverapps.Layout(fgBottomPatterns, { direction: cleverapps.UI.HORIZONTAL });
        fgBottom.setPositionRound(styles.fgBottom);
        fgBottom.setLocalZOrder(10);
        content.addChild(fgBottom);

        var gridScroll = this.gridScroll = this.createGridScroll(styles.grid, heightAdd);
        gridScroll.setPositionRound(styles.grid);
        content.addChild(gridScroll);

        var progressBar = this.progressBar = new LevelMasteryView();
        progressBar.setPositionRound(styles.progressBar);
        progressBar.setLocalZOrder(15);
        content.addChild(progressBar);

        var timer = this.createTimer(styles.timer);
        timer.setLocalZOrder(20);
        content.addChild(timer);

        return content;
    },

    createGridScroll: function (styles, heightAdd) {
        var grid = this.createRewardGrid(styles);
        var scroll = new cleverapps.UI.ScrollView(grid, {
            scrollBarEnabled: false,
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL
        });
        scroll.setContentSize(styles.width, styles.height + heightAdd);
        scroll.scrollTo(grid.rows[aisensia.levelMastery.stage]);

        return scroll;
    },

    createRewardGrid: function (styles) {
        var rows = aisensia.levelMastery.getStageRewards().map(this.createRow.bind(this, styles.row));

        var grid = new cleverapps.Layout(rows, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding,
            reversed: true
        });
        grid.rows = rows;

        return grid;
    },

    createRow: function (styles, reward, stage) {
        var circle = this.createCircle(styles.circle, stage);
        circle.setAnchorPoint(0.5, 0.5);

        var rewardCard = this.createRewardCard(styles.card, reward, stage);

        var row = new cleverapps.Layout([circle, rewardCard], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });

        return row;
    },

    createCircle: function (styles, stage) {
        var isCompleted = aisensia.levelMastery.isCompleted(stage);
        var isCurrent = aisensia.levelMastery.isCurrent(stage);
        var isLast = aisensia.levelMastery.isLastStage(stage);
        var isLocked = !isCurrent && !isCompleted;

        var circle = new cc.Node();

        var circleBg = new cc.Sprite(bundles.levelmastery.frames.circle_bg_png);
        circle.setContentSize(circleBg.getContentSize());
        circleBg.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        circle.addChild(circleBg);

        var circleColored = new cc.Sprite(isCompleted ? bundles.levelmastery.frames.circle_green_png : bundles.levelmastery.frames.circle_blue_png);
        circleColored.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        circleColored.setPositionRound(styles);
        circle.addChild(circleColored);

        var stageNumber = cleverapps.UI.generateImageText(stage + 1, cleverapps.styles.FONTS.WHITE_TEXT);
        stageNumber.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        circle.addChild(stageNumber);

        if (isCurrent) {
            AnimationsLibrary.rays(circle, { skin: "white" });
        }

        if (!isLast) {
            var line = cleverapps.UI.createScale9Sprite(bundles.levelmastery.frames.line_png);
            line.setLocalZOrder(-10);
            line.setAnchorPoint(0.5, 0);
            line.setContentSize(styles.line);
            line.setPositionRound(styles.line);
            circle.addChild(line);
        }

        if (isLocked) {
            circleBg.setScale(0.8);
            circleColored.setScale(0.8);
            stageNumber.setScale(0.8);
        }

        return circle;
    },

    createRewardCard: function (styles, reward, stage) {
        var isCompleted = aisensia.levelMastery.isCompleted(stage);
        var isCurrent = aisensia.levelMastery.isCurrent(stage);
        var isLocked = !isCurrent && !isCompleted;

        var card = cleverapps.UI.createScale9Sprite(isCompleted ? bundles.levelmastery.frames.card_bg_white_png : bundles.levelmastery.frames.card_bg_png);
        card.setContentSize(styles);

        if (isCurrent) {
            var rays = new cc.Sprite(bundles.levelmastery.frames.card_rays_png);
            rays.setScale(styles.rays.width / rays.width, styles.rays.height / rays.height);
            rays.setPositionRound(styles.rays);
            card.addChild(rays);
        }

        var icon = new cc.Sprite(isCompleted ? bundles.levelmastery.frames.card_mark_png : bundles.levelmastery.frames.hidden_reward_png);
        if (isCurrent) {
            icon = new LevelMasteryRewardView(reward);
        }
        icon.setPositionRound(styles.icon);
        cleverapps.UI.fitToBox(icon, styles.icon);
        card.addChild(icon);

        if (isLocked) {
            var lock = new cc.Sprite(bundles.levelmastery.frames.card_lock_png);
            lock.setPositionRound(styles.lock);
            card.addChild(lock);
        }

        return card;
    },

    inflateWindow: function () {
        this._super();

        cleverapps.UI.fitToBox(this.progressBar, cleverapps.styles.LevelMasteryWindow.progressBar);
    },

    getPerson: function () {
        return {
            role: "hero",
            emotion: "idle"
        };
    },

    createTimer: LevelMasteryView.prototype.createTimer,
    stopTimer: LevelMasteryView.prototype.stopTimer,
    hideTimer: LevelMasteryView.prototype.hideTimer,

    listBundles: function () {
        return ["levelmasterywindow"];
    }
});

cleverapps.styles.LevelMasteryWindow = {
    width: 775,
    height: 1100,

    CloseButton: {
        x: { align: "right", dx: -56 },
        y: { align: "top", dy: -56 }
    },

    bg: {
        x: { align: "center" },
        y: { align: "center", dy: 1 },
        width: 752,
        height: 1072
    },

    pattern: {
        x: { align: "center" },
        y: { align: "bottom", dy: 36 }
    },

    fg: {
        x: { align: "center" },
        y: { align: "top", dy: -227 }
    },

    fgBottom: {
        x: { align: "center" },
        y: { align: "bottom", dy: 54 }
    },

    progressBar: {
        x: { align: "center" },
        y: { align: "top", dy: -126 },
        width: 540,
        height: 200
    },

    grid: {
        x: { align: "center" },
        y: { align: "top", dy: -260 },
        width: 800,
        height: 760,

        margin: 20,
        padding: {
            top: 60,
            bottom: 60
        },

        row: {
            margin: 80,
            padding: {
                left: 20,
                right: 0
            },

            circle: {
                x: { align: "center" },
                y: { align: "center", dy: 2 },
                line: {
                    width: 27,
                    height: 180,
                    x: { align: "center" },
                    y: { align: "center", dy: 90 }
                }
            },

            card: {
                width: 330,
                height: 178,

                rays: {
                    x: { align: "center", dx: -3 },
                    y: { align: "center" },
                    width: 322,
                    height: 178
                },

                icon: {
                    x: { align: "center" },
                    y: { align: "center" },
                    width: 330 - 60,
                    height: 178 - 80
                },

                lock: {
                    x: { align: "right", dx: 18 },
                    y: { align: "bottom", dy: -20 }
                }
            }
        }
    },

    timer: {
        x: { align: "center" },
        y: { align: "bottom", dy: -18 },
        font: cleverapps.styles.FONTS.SIDEBAR_ICON_TEXT,
        text: {
            x: { align: "center", dx: 34 },
            y: { align: "center", dy: 3 },
            width: 200
        },
        icon: {
            frame: bundles.levelmastery.frames.clock_png,
            x: { align: "left", dx: -15 },
            y: { align: "center", dy: 3 }
        },
        padding: 0,
        background: {
            frame: bundles.sidebar.frames.icon_text_bg,
            width: 300
        }
    },

    close: {
        button_png: bundles.buttons_main.frames.window_close_white_png,
        button_on_png: bundles.buttons_main.frames.window_close_white_on_png
    }
};
