/**
 * Created by Slava on 05.09.2024
 */

cleverapps.reloadSnapshotScene = function (scene, f) {
    if (scene.villageState === Village.STATE_BUILD) {
        cleverapps.travelBook.gotoMainScene(f);
        return;
    }

    if (scene.villagePlayer && [Village.STATE_ATTACK, Village.STATE_DEFENSE, Village.STATE_ASSAULT].indexOf(scene.villageState) !== -1) {
        var player = scene.villagePlayer;
        cleverapps.focusManager.compound(f, [
            function (f) {
                cleverapps.villagePlayers.addPlayer(player);
                cleverapps.villagePlayers.attackPlayer = player;
                cleverapps.meta.switchLocation(player.locationId);
                var scene = new VillageAttackScene(true);
                cleverapps.scenes.replaceScene(scene, f);
            }
        ]);
        return;
    }

    if (scene.villagePlayer && scene.villageState === Village.STATE_RAID) {
        var player = scene.villagePlayer;
        cleverapps.focusManager.compound(f, [
            function (f) {
                cleverapps.villagePlayers.addPlayer(player);
                cleverapps.villagePlayers.resetRaidPlayer();
                cleverapps.villagePlayers.raidPlayer = player;
                cleverapps.meta.switchLocation(player.locationId);
                var scene = new VillageRaidScene(true);
                cleverapps.scenes.replaceScene(scene, f);
            }
        ]);
        return;
    }

    var level = new Level(scene.episodeNo, scene.levelNo);
    var page = cleverapps.travelBook.getPageById(level.meta.expedition) || cleverapps.travelBook.getPageById("main");
    cleverapps.travelBook.setCurrentPage(page);
    level.play(f);
};
