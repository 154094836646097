/**
 * Created by Slava on 17.10.2024
 */

var RaidStealPanelView = cc.Node.extend({
    ctor: function (village, player) {
        this._super();

        var styles = cleverapps.styles.RaidStealPanelView;
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(styles);

        var stealPanelBg = cleverapps.UI.createScale9Sprite(bundles.village.frames.control_panel_bg);
        stealPanelBg.setContentSize(styles);
        stealPanelBg.setPosition(this.width / 2, this.height / 2);
        this.addChild(stealPanelBg);

        var youStoleText = cleverapps.UI.generateOnlyText("VillageRaidScene.stoleLabel", cleverapps.styles.FONTS.RAID_NAME_TEXT);
        var number = new TextWithIcon(aisensia.getNumberWithCommas(village.goldCollected) + " " + TextWithIcon.ICONS_BY_NAME.soft, {
            font: cleverapps.styles.FONTS.RAID_GOLD_TEXT
        });

        var from = cleverapps.UI.generateOnlyText("VillageRaidScene.from", cleverapps.styles.FONTS.RAID_NAME_TEXT);
        var name = cleverapps.UI.generateOnlyText(cleverapps.UI.cutPlayerName(player.name, 8), cleverapps.styles.FONTS.RAID_STEAL_NAME_TEXT);

        var firstText = new cleverapps.Layout([youStoleText, number], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.text.wordMargin
        });

        var secondText = new cleverapps.Layout([from, name], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.text.wordMargin
        });
        secondText.setAnchorPoint(0, 0);

        var secondTextNode = new cc.Node();
        secondTextNode.setContentSize(firstText.getContentSize());
        secondTextNode.addChild(secondText);
        secondTextNode.setAnchorPoint(0.5, 0.5);

        var text = this.text = new cleverapps.Layout([firstText, secondTextNode], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.text.lineMargin
        });
        text.setPositionRound(styles.text);
        this.addChild(text);

        this.setContentSize(stealPanelBg.getContentSize());
    },

    show: function () {
        this.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.MoveBy(0, 0, -this.height),
            new cc.PlaySound(bundles.main.urls.control_panel_bg),
            new cc.DelayTime(0.2),
            new cc.Show(),
            new cc.MoveBy(0.3, 0, this.height).easing(cc.easeOut(3))
        ));
    },

    hide: function () {
        this.runAction(new cc.Sequence(
            new cc.MoveBy(0.3, 0, -this.height).easing(cc.easeIn(3)),
            new cc.Hide()
        ));
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    RAID_STEAL_NAME_TEXT: {
        name: "nostroke",
        size: 36,
        color: new cc.Color(146, 129, 181),
        stroke: undefined,
        shadow: undefined
    },
    RAID_OK_BUTTON: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.RaidStealPanelView = {
    width: 550,
    height: 215,

    button: {
        width: 175,
        height: 90,
        x: { align: "right", dx: -60 },
        y: { align: "center", dy: -20 }
    },

    text: {
        x: { align: "center", dx: -20 },
        y: { align: "center", dy: 20 },

        wordMargin: 20,
        lineMargin: 15
    }
};