/**
 * Created by Mikhail Menshenin on 25.07.2024
 */

var VillageProgressView = cc.Node.extend({
    avoidNode: "ProgressView",

    ctor: function (village) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.VillageProgressView;

        var progressBar = this.progressBar = new ScaledProgressBar({
            type: ScaledProgressBar.Types.green_large,
            barText: {
                dy: 2,
                font: cleverapps.styles.FONTS.VILLAGE_PROGRESS_BAR_TEXT,
                text: "%from%/%to%@@",
                icons: {
                    "@@": bundles.village.frames.star
                }
            }
        });
        progressBar.setLength(styles.size[cleverapps.resolution.mode].width);
        progressBar.setScale(styles.scale[cleverapps.resolution.mode])
        this.setContentSize(progressBar.getContentSize());
        progressBar.setGoal(village.getGoal());
        progressBar.setPercentage(village.progress);
        progressBar.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        this.addChild(progressBar);

        var titleBg = new cleverapps.UI.createScale9Sprite(bundles.village.frames.progressbar_substrate);
        titleBg.setContentSize(styles.titleBg);
        titleBg.setPositionRound(styles.titleBg);
        this.addChild(titleBg);

        var text = cleverapps.UI.generateOnlyText("VillageScene.Title." + village.name, cleverapps.styles.FONTS.VILLAGE_TITLE_TEXT);
        text.setPositionRound(styles.text);
        titleBg.addChild(text);

        var rewardIcon = new cc.Sprite(bundles.village.frames.progress_reward_png);
        rewardIcon.setPositionRound(styles.reward);
        this.addChild(rewardIcon);

        cleverapps.UI.wrap(this);

        village.on("changeProgress", this.updateProgress.bind(this, village), this);
        this.updateProgress(village);

        this.updateSize();
    },

    updateSize: function () {
        var styles = cleverapps.styles.VillageProgressView;

        this.progressBar.setLength(styles.size[cleverapps.resolution.mode].width);
        this.progressBar.setScale(styles.scale[cleverapps.resolution.mode])
    },

    updateProgress: function (village, cb) {
        this.progressBar.setPercentage(village.progress, {
            animated: true,
            callback: cb || function () { }
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    VILLAGE_PROGRESS_BAR_TEXT: {
        name: "default",
        size: 40
    },

    VILLAGE_TITLE_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        name: "nostroke"
    }
});

cleverapps.styles.VillageProgressView = {
    size: [
        { width: 675 },
        { width: 900 },
        { width: 900 }
    ],

    scale: [0.9, 0.8, 0.8],

    build: {
        x: { align: "left", dx: -80 },
        y: { align: "center", dy: 10 }
    },

    reward: [
        {
            x: { align: "right", dx: -25 },
            y: { align: "center", dy: 16 }
        },
        {
            x: { align: "right", dx: -80 },
            y: { align: "center", dy: 16 }
        },
        {
            x: { align: "right", dx: -80 },
            y: { align: "center", dy: 16 }
        }
    ],

    titleBg: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 53 },
        width: 160,
        height: 50,
    },

    text: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -2 }
    }
};

RestoreProgressButton.OverlappingNodes = RestoreProgressButton.OverlappingNodes.concat("ProgressView");